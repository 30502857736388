import dynamic from "next/dynamic";
import StylesIcon from "@/styles/Icons.module.css";
import { useEffect, useState } from "react";
import Skeleton from "../Skeleton";
import Link from "next/link";
const TopNewsSingle = dynamic(() =>
  import("@/components/top-news/TopNewsSingle")
);

const TopNews = ({ title }) => {
  const [topNewsCount, setTopNewsCount] = useState(0);
  const [topNewsOne, setTopNewsOne] = useState([]);
  const [topNewsTwo, setTopNewsTwo] = useState([]);
  useEffect(() => {
    getTopNewsData();
  }, []);

  const setTopNewsPrev = () => {
    if (topNewsCount != 0) {
      setTopNewsCount((prevState) => prevState - 1);
    }
  };
  const setTopNewsNext = () => {
    if (topNewsCount != 1) {
      setTopNewsCount((prevState) => prevState + 1);
    }
  };
  const getTopNewsData = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL + "/frontend-bn/top-news/list/bd"
      );
      const resData = await res.json();
      setTopNewsOne(resData.data_one);
      setTopNewsTwo(resData.data_two);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="sidebar order-lg-1 widget widget-headlines d-none d-md-block">
      <Link
        href={"/news/subcategory/uckrhmz7"}
        className="section-title"
        style={{
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          textAlign: "center",
        }}
      >
        <h2 className="title" style={{ textAlign: "center", width: "100%" }}>
          {title}
        </h2>
      </Link>
      {topNewsCount == 0
        ? topNewsOne.length > 0
          ? topNewsOne.map((item, index) => (
              <div
                className={"grid-layout"}
                key={index}
                style={{
                  margin: "0px",
                  display: "block",
                  padding: "0px 15px",
                }}
                itemScope
                itemType="http://schema.org/NewsArticle"
              >
                <TopNewsSingle news={item} key={item.slug + index} />
                <hr />
              </div>
            ))
          : [...Array(5)].map((e, i) => (
              <Skeleton
                type={"small-grid"}
                width={"100%"}
                height={"95px"}
                key={i}
              />
            ))
        : ""}
      {topNewsCount == 1
        ? topNewsTwo.map((item, index) => (
            <div
              className={"grid-layout"}
              key={index}
              style={{
                margin: "0px",
                display: "block",
                padding: "0px 15px",
              }}
              itemScope
              itemType="http://schema.org/NewsArticle"
            >
              <TopNewsSingle news={item} key={item.slug + index} />
              <hr />
            </div>
          ))
        : ""}

      <div style={{ width: "100%" }}>
        <div className="text-center custom-slider-btns">
          <button
            type="button"
            aria-label="Prev News"
            title="Prev News"
            className="custom-slider-left-btn"
            onClick={setTopNewsPrev}
          >
            <i
              className={`${StylesIcon.chevron__left}`}
              style={{ color: "#fff" }}
            ></i>
          </button>
          <button
            type="button"
            aria-label="Next News"
            title="Next News"
            className="custom-slider-right-btn"
            onClick={setTopNewsNext}
          >
            <i
              className={`${StylesIcon.chevron__right}`}
              style={{ color: "#fff" }}
            ></i>
          </button>
        </div>
      </div>
    </div>
  );
};
export default TopNews;
